<template>
  <div class="app-box">
    <x-item-nav title="库存和一般价格" @click.native="jumpPage(1);"></x-item-nav>
    <x-item-nav title="客户特殊价格" @click.native="jumpPage(2);"></x-item-nav>
  </div>
</template>

<script>
import itemNav from "@/components/itemNav";

export default {
  data () {
    return {};
  },
  created () {
    document.title = "库存及价格";
  },
  methods: {
    jumpPage (index) {
      let url = "";
      switch (index) {
        case 1:
          url = "/productInquiry/productInfo";
          break;
        case 2:
          url = "/productInquiry/specialPrice";
          break;
        default:
          break;
      }
      this.$router.push({
        path: url
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "货品查询";
      }
    }
  },
  components: {
    [itemNav.name]: itemNav
  }
};
</script>

<style scoped>
</style>
